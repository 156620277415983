import ChartDataLabels from "chartjs-plugin-datalabels";
import React, { useEffect, useState, useRef } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  plugins,
} from "chart.js";
import { Doughnut, HorizontalBar } from "react-chartjs-2";
import {
  getDashboardData,
  getDashboardDataWithType,
  getDashboardMonthData,
  getDashboardColumnsData,
  setUrlMonithorService,
  getDashboardObjectStatus,
} from "../../actions/monithor.action";
import ControlledInputRoundedForm from "../../components/InputForm/ControlledInputRoundedForm";
import { Grid, MenuItem } from "@material-ui/core";
import { useForm } from "react-hook-form";
import IconEspecialButton from "../../components/ButtonForm/IconEspecialButton.component";
import { Search, Launch } from "@material-ui/icons";
import CustomTable from "../../components/Table/CustomTable.component";
import { useSelector } from "react-redux";
import "./dashboard.css";

ChartJS.plugins.register({
  id: "dashboard",
  ArcElement,
  Tooltip,
  Legend,
  plugins,
});

export function MonitoringDashBoardTransactions() {
  const [dashboardData, setDashboardData] = useState({
    labels: [],
    datasets: [{ data: [], backgroundColor: [] }],
  });
  const [originalDashboardData, setOriginalDashboardData] = useState(null);
  const [monthData, setMonthData] = useState({
    labels: [],
    datasets: [{ data: [], backgroundColor: [] }],
  });
  const [originalMonthData, setOriginalMonthData] = useState(null);
  const [objectTypeData, setObjectTypeData] = useState({
    labels: [],
    datasets: [{ data: [], backgroundColor: [] }],
  });
  const [originalObjectTypeData, setOriginalObjectTypeData] = useState(null);
  const [isMonthData, setIsMonthData] = useState(false);
  const [isObjectTypeData, setIsObjectTypeData] = useState(false);
  const [currentIntegration, setCurrentIntegration] = useState("");
  const [currentMonth, setCurrentMonth] = useState("");
  const [currentEventCode, setCurrentEventCode] = useState("");
  const [option, setOption] = useState(null);
  const [records, setRecords] = useState([]);
  const [columns, setColumns] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [transformedDataTable, setTransformedDataTable] = useState([]);
  const [isSecondDataTable, setIsSecondDataTable] = useState(false);
  const [isThirdDataTable, setIsThirdDataTable] = useState(false);
  const [isData, setIsData] = useState(false);
  const [shouldMergeMonth, setShouldMergeMonth] = useState(false);
  const [shouldDisplayStatus, setShouldDisplayStatus] = useState(true);
  const [objectStatusData, setObjectStatusData] = useState([]);
  const [visibleDashboardSegments, setVisibleDashboardSegments] = useState([]);
  const [visibleMonthSegments, setVisibleMonthSegments] = useState([]);
  const [visibleObjectTypeSegments, setVisibleObjectTypeSegments] = useState(
    []
  );
  const [dashboardThreshold, setDashboardThreshold] = useState(3);
  const [monthThreshold, setMonthThreshold] = useState(3);
  const [objectTypeThreshold, setObjectTypeThreshold] = useState(3);
  const [selectedDashboardIndexes, setSelectedDashboardIndexes] = useState([]);
  const [selectedMonthIndexes, setSelectedMonthIndexes] = useState([]);
  const [selectedObjectTypeIndexes, setSelectedObjectTypeIndees] = useState([]);
  const chartRef = useRef(null);
  let params = new URLSearchParams(window.location.search);
  const [environmentURL, setEnvironmentURL] = useState(
    params.get("env") ? `https://${params.get("env")}.parameta.co` : ""
  );
  const [fullURL] = useState(
    params.get("fullurl") ? params.get("fullurl") : ""
  );
  const [version, setVersion] = useState(parseInt(params.get("ver") ?? "1"));

  const getCurrentDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const handleDataWithDate = () => {
    setIsObjectTypeData(false);
    setIsMonthData(false);
    setIsSecondDataTable(false);
    setIsThirdDataTable(false);
    fetchData(control.getValues().dateStart, control.getValues().dateEnd);
    setSelectedDashboardIndexes([]);
    setSelectedMonthIndexes([]);
    setSelectedObjectTypeIndees([]);
    setShouldDisplayStatus(true);
  };

  const chartContainerStyle = {
    flexDirection: "column",
    alignItems: "center",
    width: "50rem",
    height: "25rem",
    justifyContent: "space-between",
    minWidth:"269px"
  };

  const chartItem = {
    paddingLeft: "0.5vw",
    marginBottom: "0.1rem",
    cursor: "pointer"
  };

  const getUserFriendlyName = (key) => {
    if (key === "OBJECT_TYPE") return "Integración";

    if (key === "N") return "Cantidad de Transacciones";

    if (key === "EVENT_CODE") return "Tipo de Evento";

    if (key === "MONTH_") return "Mes";

    if (key === "OK") return "Exitosas";

    if (key === "FAIL") return "Fallidas (Negocio)";

    if (key === "FAIL_P") return "Fallidas (Plataforma)";
  };

  const arrayToCSV = (array) => {
    if (!array.length) return "";

    const headers = Object.keys(array[0]);
    const userFriendlyHeaders = headers.map((key) => getUserFriendlyName(key));

    const csvRows = [
      userFriendlyHeaders.join(","),
      ...array.map((row) =>
        headers
          .map((header) => `"${String(row[header]).replace(/"/g, '""')}"`)
          .join(",")
      ),
    ];

    return csvRows.join("\n");
  };

  const downloadCSV = (csvString, filename) => {
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const exportCSV = (data) => {
    const csvString = arrayToCSV(data);
    const currentDate = getCurrentDate();
    const filename = `DASHBOARD_${currentDate}.csv`;
    downloadCSV(csvString, filename);
  };

  const calculatePercentages = (data) => {
    const sum = data.reduce((a, b) => a + b, 0);
    return data.map((value) => (value / sum) * 100);
  };

  const combinePercentagesAndVisibility = (
    percentages,
    visibleDashboardSegments
  ) => {
    return percentages.map((percentage, index) => ({
      percentage,
      isVisible: visibleDashboardSegments[index],
    }));
  };

  const toggleDashboardSegment = (index) => {
    setSelectedDashboardIndexes((prev) => {
      if (prev.includes(index)) {
        return prev.filter((i) => i !== index);
      } else {
        return [...prev, index];
      }
    });
    let newThreshold;

    const percentages = calculatePercentages(originalDashboardData);
    const newVisibleSegments = [...visibleDashboardSegments];
    newVisibleSegments[index] = !newVisibleSegments[index];
    setVisibleDashboardSegments(newVisibleSegments);
    const combinedData = combinePercentagesAndVisibility(
      percentages,
      newVisibleSegments
    );
  };

  const toggleMonthSegment = (index) => {
    setSelectedMonthIndexes((prev) => {
      if (prev.includes(index)) {
        return prev.filter((i) => i !== index);
      } else {
        return [...prev, index];
      }
    });
    let newThreshold;

    const percentages = calculatePercentages(originalMonthData);
    const newVisibleSegments = [...visibleMonthSegments];
    newVisibleSegments[index] = !newVisibleSegments[index];
    setVisibleMonthSegments(newVisibleSegments);
    const combinedData = combinePercentagesAndVisibility(
      percentages,
      newVisibleSegments
    );
  };

  const toggleObjectTypeSegment = (index) => {
    setSelectedObjectTypeIndees((prev) => {
      if (prev.includes(index)) {
        return prev.filter((i) => i !== index);
      } else {
        return [...prev, index];
      }
    });
    let newThreshold;

    const percentages = calculatePercentages(originalMonthData);
    const newVisibleSegments = [...visibleObjectTypeSegments];
    newVisibleSegments[index] = !newVisibleSegments[index];
    setVisibleObjectTypeSegments(newVisibleSegments);
    const combinedData = combinePercentagesAndVisibility(
      percentages,
      newVisibleSegments
    );
  };

  const filteredDashboardData = {
    labels: dashboardData.labels,
    datasets: [
      {
        data: dashboardData.datasets[0].data.map((value, index) =>
          visibleDashboardSegments[index] ? value : 0
        ),
        backgroundColor: dashboardData.datasets[0].backgroundColor,
      },
    ],
  };

  const filteredMonthData = {
    labels: monthData.labels,
    datasets: [
      {
        data: monthData.datasets[0].data.map((value, index) =>
          visibleMonthSegments[index] ? value : 0
        ),
        backgroundColor: monthData.datasets[0].backgroundColor,
      },
    ],
  };

  const filteredObjectTypeData = {
    labels: objectTypeData.labels,
    datasets: [
      {
        data: objectTypeData.datasets[0].data.map((value, index) =>
          visibleObjectTypeSegments[index] ? value : 0
        ),
        backgroundColor: objectTypeData.datasets[0].backgroundColor,
      },
    ],
  };

  async function fetchData(startDate, endDate) {
    try {
      setShouldMergeMonth(false);
      const fetchedData = await getDashboardData(startDate, endDate);

      const data = fetchedData.datasets[0].data;
      const labels = fetchedData.labels;
      const result = labels.map((label, index) => ({
        OBJECT_TYPE: label,
        N: data[index].toString(),
      }));

      fetchObjectStatus(startDate, endDate);
      setDashboardData(fetchedData);
      setVisibleDashboardSegments(
        new Array(fetchedData.labels.length).fill(true)
      );
      setOriginalDashboardData(fetchedData.datasets[0].data);
      setDataTable(result);
    } catch (error) {
      console.error("Error al fetchData: ", error);
    }
  }

  async function fetchObjectStatus(startDate, endDate, type, month) {
    try {
      const fetchedData = await getDashboardObjectStatus(startDate, endDate, type, month);
      setObjectStatusData(fetchedData);
    } catch (error) {
      console.error("Error al fetchObjectStatus: ", error);
    }
  }

  const monthNameToNumber = {
    Enero: 1,
    Febrero: 2,
    Marzo: 3,
    Abril: 4,
    Mayo: 5,
    Junio: 6,
    Julio: 7,
    Agosto: 8,
    Septiembre: 9,
    Octubre: 10,
    Noviembre: 11,
    Diciembre: 12,
  };

  const aggregateData = (data, withMonth) => {
    const aggregatedMap = new Map();

    data.forEach((item) => {
      const key = withMonth
        ? `${item.OBJECT_TYPE}-${item.MONTH_.toString()}`
        : item.OBJECT_TYPE;

      if (!aggregatedMap.has(key)) {
        aggregatedMap.set(key, {
          OBJECT_TYPE: item.OBJECT_TYPE,
          MONTH_: withMonth ? item.MONTH_.toString() : "0",
          FAIL: 0,
          OK: 0,
        });
      }

      const aggregatedItem = aggregatedMap.get(key);
      if (item.OBJECT_STATUS === "FAIL") {
        aggregatedItem.FAIL += item.N;
      } else if (item.OBJECT_STATUS === "OK") {
        aggregatedItem.OK += item.N;
      }
    });

    return aggregatedMap;
  };

  const mapDataTable = (dataTable, aggregatedMap, withMonth) => {
    return dataTable.map((item) => {
      const monthNumber = withMonth ? monthNameToNumber[item.MONTH_.split("-").length > 1 ? item.MONTH_.split("-")[1] : item.MONTH_] : "0";
      const key = withMonth
        ? `${item.OBJECT_TYPE}-${monthNumber}`
        : item.OBJECT_TYPE;

      const aggregatedItem = aggregatedMap.get(key) || {
        OBJECT_TYPE: item.OBJECT_TYPE,
        MONTH_: monthNumber.toString(),
        FAIL: 0,
        OK: 0,
      };

      const result = {
        OBJECT_TYPE: item.OBJECT_TYPE,
        ...(item.MONTH_ && { MONTH_: item.MONTH_ }),
        ...(item.EVENT_CODE && { EVENT_CODE: item.EVENT_CODE }),
        N: new Intl.NumberFormat('es-ES', {style:'decimal'}).format(item.N),
        ...(item.EVENT_CODE
          ? {}
          : {
              OK: `${(aggregatedItem.OK/item.N * 100).toFixed(1)} % (${new Intl.NumberFormat('es-ES', {style:'decimal'}).format(aggregatedItem.OK)})` || "0.0 % (0)",
              FAIL: `${(aggregatedItem.FAIL/item.N * 100).toFixed(1)} % (${new Intl.NumberFormat('es-ES', {style:'decimal'}).format(aggregatedItem.FAIL)})` || "0.0 % (0)",
              FAIL_P: "0.0 % (0)",
            }),
      };

      return result;
    });
  };

  useEffect(() => {
    try {
      const aggregatedMap = aggregateData(objectStatusData, shouldMergeMonth);
      const result = mapDataTable(dataTable, aggregatedMap, shouldMergeMonth);
      setTransformedDataTable(result);
    } catch (err) {
      console.error(err);
    }
  }, [dataTable, objectStatusData, shouldMergeMonth]);

  useEffect(() => {
    try {
      if (dashboardData.labels.length == 0) {
        setIsData(false);
      } else {
        setIsData(true);
      }
    } catch (e) {
      console.error(e);
    }
  }, [dashboardData]);

  async function fetchDataWithType(
    shouldUpdateTable,
    startDate,
    endDate,
    type,
    month
  ) {
    try {
      const fetchedData = await getDashboardDataWithType(
        startDate,
        endDate,
        type,
        month
      );

      const data = fetchedData.datasets[0].data;
      const labels = fetchedData.labels;
      const result = labels.map((label, index) => ({
        OBJECT_TYPE: type,
        EVENT_CODE: label,
        N: data[index].toString(),
        MONTH_: month,
      }));

      if (shouldUpdateTable) {
        setDataTable(result);
        fetchObjectStatus(startDate, endDate, type, month);
      }

      setObjectTypeData(fetchedData);
      setVisibleObjectTypeSegments(
        new Array(fetchedData.labels.length).fill(true)
      );
      setOriginalObjectTypeData(fetchedData.datasets[0].data);
      setCurrentIntegration(type);
      setCurrentMonth(month);
      setIsObjectTypeData(true);
    } catch (error) {
      console.error("Error al fetchDataWithType:", error);
    }
  }

  async function fetchMonthData(
    shouldUpdateTable,
    startDate,
    endDate,
    type,
    eventCode,
    month
  ) {
    try {
      const fetchedData = await getDashboardMonthData(
        startDate,
        endDate,
        type,
        eventCode,
        month
      );
      const data = fetchedData.datasets[0].data;
      const labels = fetchedData.labels;

      const result = labels.map((label, index) => ({
        OBJECT_TYPE: type,
        MONTH_: label,
        N: data[index].toString(),
        EVENT_CODE: eventCode,
      }));

      setCurrentEventCode(eventCode);
      setMonthData(fetchedData);
      setVisibleMonthSegments(new Array(fetchedData.labels.length).fill(true));
      setOriginalMonthData(fetchedData.datasets[0].data);

      if (shouldUpdateTable) {
        setDataTable(result);
        fetchObjectStatus(startDate, endDate, type, month);
      }
      setIsMonthData(true);
      fetchRecords();
      return fetchedData;
    } catch (error) {
      console.error("Error al fetchMonthData:", error);
    }
  }

  async function fetchRecords() {
    setRecords(await getDashboardColumnsData());
  }

  const { getEnterpriseByIDResponse } = useSelector(
    (store) => store.enterpriseReducer
  );

  useEffect(() => {
    if (getEnterpriseByIDResponse.monitoringService) {
      setEnvironmentURL(getEnterpriseByIDResponse.monitoringService);
      setVersion(getEnterpriseByIDResponse.monitoringVersion);
    }

    if (version && (environmentURL || fullURL)) {
      setUrlMonithorService(fullURL ? fullURL : environmentURL);
      fetchData(control.getValues().dateStart, control.getValues().dateEnd);
      fetchRecords();
    }
  }, [getEnterpriseByIDResponse, version, environmentURL, fullURL]);

  const { control, errors } = useForm({
    defaultValues: {
      dateStart: "",
      dateEnd: getCurrentDate(),
    },
    shouldUnregister: false,
    mode: "onChange",
  });

  const sortByPriority = (a, b) => {
    if (a.id === "OBJECT_TYPE") return -1;
    if (b.id === "OBJECT_TYPE") return 1;

    if (a.id === "N") return 1;
    if (b.id === "N") return -1;

    return 0;
  };

  const addRecords = (array) => {
    const newRecords = [...array, { id: "OK" }, { id: "FAIL" }, { id: "FAIL_P" }];
    return newRecords;
  };

  useEffect(() => {
    try {
      if (records.length) {
        setIsData(true);
        const newColumns = [];
        const newQueriesColumns = [];
        const sortedRecords = records.sort(sortByPriority);
        const newRecords = addRecords(sortedRecords);
        newRecords.forEach((key) => {
          if (
            key.id !== "OBJECT_STATUS" &&
            key.id !== "OBJECT_DATE" &&
            key.id !== "COMPANY" &&
            key.id !== "YEAR_" &&
            key.id !== "DAY_"
          ) {
            if (key.id === "EVENT_CODE" && !isSecondDataTable) return;

            if (key.id === "MONTH_" && !isThirdDataTable) return;

            if (key.id === "OK" && !shouldDisplayStatus) return;

            if (key.id === "FAIL" && !shouldDisplayStatus) return;

            if (key.id === "FAIL_P" && !shouldDisplayStatus) return;

            newColumns.push({
              id: key.id,
              label: getUserFriendlyName(key.id),
              minWidth: 40,
              alignHeader: "left",
              haveDropDown: true,
              wordLength: 100,
            });
            let type = typeof records[0][key];
            newQueriesColumns.push({
              fieldName: key.id,
              fieldValue: "",
              comparision: "6",
              groupAction: "9",
              type: type,
            });
          }
        });
        setColumns(newColumns);
        records.forEach((rec, index) => {
          rec.id = rec.id ?? index + 1;
        });
      }
    } catch (err) {
      console.error(err);
    }
  }, [records, isSecondDataTable, isThirdDataTable, currentMonth]);

  const integrationChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutoutPercentage: 50,
    plugins: {
      type: "doughnutWithTextCenter",
      datalabels: {
        color: "#fff",
        display: "auto",
        display: (context) => {
          const value = context.dataset.data[context.dataIndex];
          const sum = originalDashboardData.reduce((a, b) => a + b, 0);
          return (value / sum) * 100;
        },
        formatter: (value, context) => {
          if (!originalDashboardData) return "";
          let sum = originalDashboardData.reduce((a, b) => a + b, 0);
          let percentage = (value / sum) * 100;
          return percentage < 0.01 ? "< 0.01%" : percentage.toFixed(2) + "%";
        },
        anchor: "center",
        align: "center",
        offset: 1,
        clip: true,
        backgroundColor: (context) => {
          let sum = originalDashboardData.reduce((a, b) => a + b, 0);
          let percentage =
            (context.dataset.data[context.dataIndex] / sum) * 100;
          return "rgba(0, 0, 0, 0.3)";
        },
      },
      totalText: {
        text: new Intl.NumberFormat('es-ES', {style:'decimal'}).format(originalDashboardData?.reduce((a, b) => a + b, 0) ?? 0),
        color: "#6D6E71",
        fontSize: 28,
      },
    },
    legend: {
      display: false,
      labels: {
        usePointStyle: true,
      },
    },
    title: {
      display: true,
      text: "# Transacciones por Integración",
    },
    onClick: (e, element) => {
      if (element.length > 0) {
        let ind = element[0]._index;
        setIsSecondDataTable(false);
        setIsThirdDataTable(true);
        setShouldMergeMonth(true);
        setShouldDisplayStatus(true);
        fetchDataWithType(
          false,
          control.getValues().dateStart,
          control.getValues().dateEnd,
          dashboardData.labels[ind]
        );
        fetchMonthData(
          true,
          control.getValues().dateStart,
          control.getValues().dateEnd,
          dashboardData.labels[ind]
        );
      }
    },
  };

  const monthChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutoutPercentage: 50,
    plugins: {
      type: "doughnutWithTextCenter",
      datalabels: {
        color: "#fff",
        display: (context) => {
          const value = context.dataset.data[context.dataIndex];
          const sum = originalMonthData.reduce((a, b) => a + b, 0);
          return (value / sum) * 100; //> monthThreshold;
        },
        formatter: (value, context) => {
          if (!originalMonthData) return "";
          let sum = originalMonthData.reduce((a, b) => a + b, 0);
          let percentage = (value / sum) * 100;
          return percentage < 0.01 ? "< 0.01%" : percentage.toFixed(2) + "%";
        },
        anchor: "center",
        align: "center",
        offset: 1,
        clip: true,
        backgroundColor: (context) => {
          let sum = originalMonthData.reduce((a, b) => a + b, 0);
          let percentage =
            (context.dataset.data[context.dataIndex] / sum) * 100;
          return "rgba(0, 0, 0, 0.3)";

          /*> monthThreshold
            ? "rgba(0, 0, 0, 0.3)"
            : "transparent";*/
        },
      },
      totalText: {
        text: new Intl.NumberFormat('es-ES', {style:'decimal'}).format(originalMonthData?.reduce((a, b) => a + b, 0) ?? 0),
        color: "#6D6E71",
        fontSize: 28,
      },
    },
    legend: {
      display: false,
      labels: {
        usePointStyle: true,
      },
    },
    title: {
      display: true,
      text: (currentEventCode ? ["# Transacciones por Mes",`${currentIntegration}`,currentEventCode] : ["# Transacciones por Mes",`${currentIntegration}`]),
    },
    onClick: (e, element) => {
      if (element.length > 0) {
        let ind = element[0]._index;
        setIsSecondDataTable(true);
        setIsThirdDataTable(true);
        setShouldDisplayStatus(false);
        fetchDataWithType(
          true,
          control.getValues().dateStart,
          control.getValues().dateEnd,
          currentIntegration,
          monthData.labels[ind]
        );
      }
    },
  };

  const typeChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutoutPercentage: 50,
    plugins: {
      type: "doughnutWithTextCenter",
      datalabels: {
        color: "#fff",
        display: (context) => {
          const value = context.dataset.data[context.dataIndex];
          const sum = originalObjectTypeData.reduce((a, b) => a + b, 0);
          return (value / sum) * 100; //> monthThreshold;
        },
        formatter: (value, context) => {
          if (!originalObjectTypeData) return "";
          let sum = originalObjectTypeData.reduce((a, b) => a + b, 0);
          let percentage = (value / sum) * 100;
          return percentage < 0.01 ? "< 0.01%" : percentage.toFixed(2) + "%";
        },
        anchor: "center",
        align: "center",
        offset: 1,
        clip: true,
        backgroundColor: (context) => {
          let sum = originalObjectTypeData.reduce((a, b) => a + b, 0);
          let percentage =
            (context.dataset.data[context.dataIndex] / sum) * 100;
          return "rgba(0, 0, 0, 0.3)";

          /*> monthThreshold
              ? "rgba(0, 0, 0, 0.3)"
              : "transparent";*/
        },
      },
      totalText: {
        text: new Intl.NumberFormat('es-ES', {style:'decimal'}).format(originalObjectTypeData?.reduce((a, b) => a + b, 0) ?? 0),
        color: "#6D6E71",
        fontSize: 28,
      },
    },
    legend: {
      display: false,
      labels: {
        usePointStyle: true,
      },
    },
    title: {
      display: true,
      text: (currentMonth ? ["# Transacciones por Evento",currentIntegration,currentMonth] : ["# Transacciones por Evento",currentIntegration]),
    },
    onClick: (e, element) => {
      if (element.length > 0) {
        let ind = element[0]._index;
        setIsSecondDataTable(true);
        setIsThirdDataTable(true);
        setShouldDisplayStatus(false);
        fetchMonthData(
          true,
          control.getValues().dateStart,
          control.getValues().dateEnd,
          currentIntegration,
          objectTypeData.labels[ind],
          currentMonth
        );
      }
    },
  };

  ChartJS.plugins.register({
    id: "totalText",
    afterDatasetsDraw: function (chart, _) {
      if (chart.options.plugins.type == "doughnutWithTextCenter") {
        const ctx = chart.ctx;
        const width = chart.width;
        const height = chart.height;
        const fontSize = chart.options.plugins.totalText.fontSize;
        ctx.font = fontSize + "px Arial";
        ctx.fillStyle = chart.options.plugins.totalText.color;
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        const text = chart.options.plugins.totalText.text;
        const textX = Math.round(width / 2);
        const textY = Math.round(height / 1.75);
        ctx.fillText(text, textX, textY);
      }
    },
  });

  return (
    <>
      <Grid item container spacing={1} sm={6} xs={12}>
        <Grid item md={6} sm={6} xs={12}>
          <ControlledInputRoundedForm
            id="dateStart"
            name="dateStart"
            label="Fecha inicio"
            control={control}
            fullWidth
            type="date"
            style={{ marginLeft: "10px", marginTop: "10px" }}
            error={errors.dateStart}
            shrink={true}
            helperText={errors.dateStart?.message}
          ></ControlledInputRoundedForm>
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <ControlledInputRoundedForm
            id="dateEnd"
            name="dateEnd"
            label="Fecha fin"
            control={control}
            fullWidth
            type="date"
            shrink={true}
            style={{ marginLeft: "10px", marginTop: "10px" }}
            error={errors.dateEnd}
            helperText={errors.dateEnd?.message}
          ></ControlledInputRoundedForm>
        </Grid>
        <Grid
          item
          container
          spacing={2}
          sm={4}
          xs={12}
          style={{
            paddingLeft: "22px",
            paddingTop: "10px",
          }}
        ></Grid>
      </Grid>

      {isData && (
        <>
          <Grid
            item
            container
            sm={3}
            xs={12}
            style={{
              paddingLeft: "20px",
              paddingTop: "15px",
            }}
          >
            <IconEspecialButton
              aria-controls="customized-menu"
              aria-haspopup="true"
              variant="contained"
              size={"large"}
              startIcon={<Search />}
              onClick={() => {
                handleDataWithDate();
              }}
            >
              Buscar
            </IconEspecialButton>
            <IconEspecialButton
              aria-controls="customized-menu"
              aria-haspopup="true"
              variant="contained"
              size={"small"}
              onClick={() => exportCSV(transformedDataTable)}
              startIcon={<Launch />}
            >
              Exportar
            </IconEspecialButton>
          </Grid>
        </>
      )}

      {isData && (
        <>
          <Grid item container spacing={0} sm={4} xs={12} style={{minWidth:"430px"}}>
            <div className="doughnut">
              {dashboardData.labels.length > 0 && (
                <ul className="custom-legend custom-legend-left">
                  {dashboardData.labels.map((label, index) => (
                    <li
                      key={index}
                      // onClick={() => toggleDashboardSegment(index)}
                      onClick={() =>{
                        setIsSecondDataTable(false);
                        setIsThirdDataTable(true);
                        setShouldMergeMonth(true);
                        setShouldDisplayStatus(true);
                        fetchDataWithType(
                          false,
                          control.getValues().dateStart,
                          control.getValues().dateEnd,
                          dashboardData.labels[index]
                        );
                        fetchMonthData(
                          true,
                          control.getValues().dateStart,
                          control.getValues().dateEnd,
                          dashboardData.labels[index]
                        );
                      }}

                      className={
                        selectedDashboardIndexes.includes(index)
                          ? "selected"
                          : ""
                      }
                    >
                      <span
                        style={{
                          backgroundColor: dashboardData.datasets[0].backgroundColor[index],
                        }}
                      ></span>
                      {label}
                    </li>
                  ))}
                </ul>
              )}
              <div className="canva">
                <Doughnut
                  data={filteredDashboardData}
                  options={{ ...integrationChartOptions }}
                  width={269}
                  height={300}
                  ref={chartRef}
                />
              </div>
            </div>
            {/* <Grid item md={6} sm={6} xs={12} style={chartContainerStyle}>
              <div style={chartItem}>
                <Doughnut
                  data={filteredDashboardData}
                  options={{ ...integrationChartOptions }}
                  width={300}
                  height={300}
                  ref={chartRef}
                />
              </div>
              {dashboardData.labels.length > 0 && (
                <ul className="custom-legend">
                  {dashboardData.labels.map((label, index) => (
                    <li
                      key={index}
                      onClick={() => toggleDashboardSegment(index)}
                      className={
                        selectedDashboardIndexes.includes(index)
                          ? "selected"
                          : ""
                      }
                    >
                      <span
                        style={{
                          backgroundColor:
                            dashboardData.datasets[0].backgroundColor[index],
                        }}
                      ></span>
                      {label}
                    </li>
                  ))}
                </ul>
              )}
            </Grid> */}
          </Grid>
          {isMonthData && (
            <Grid item md={3} xs={12} style={chartContainerStyle}>
              <div style={chartItem}>
                <Doughnut
                  data={filteredMonthData}
                  options={{ ...monthChartOptions }}
                  width={300}
                  height={300}
                  ref={chartRef}
                />
              </div>
              {monthData.labels.length > 0 && (
                <ul className="custom-legend">
                  {monthData.labels.map((label, index) => (
                    <li
                      key={index}
                      // onClick={() => toggleMonthSegment(index)}
                      onClick={() => {
                        setIsSecondDataTable(true);
                        setIsThirdDataTable(true);
                        setShouldDisplayStatus(false);
                        fetchDataWithType(
                          true,
                          control.getValues().dateStart,
                          control.getValues().dateEnd,
                          currentIntegration,
                          monthData.labels[index]
                        );
                      }}
                      className={
                        selectedMonthIndexes.includes(index) ? "selected" : ""
                      }
                    >
                      <span
                        style={{
                          backgroundColor:
                            monthData.datasets[0].backgroundColor[index],
                        }}
                      ></span>
                      {label}
                    </li>
                  ))}
                </ul>
              )}
            </Grid>
          )}
          {isObjectTypeData && (
            <Grid item md={3} xs={12} style={chartContainerStyle}>
              <div style={chartItem}>
                <Doughnut
                  data={filteredObjectTypeData}
                  options={{ ...typeChartOptions }}
                  width={300}
                  height={300}
                  ref={chartRef}
                />
              </div>
              {objectTypeData.labels.length > 0 && (
                <ul className="custom-legend">
                  {objectTypeData.labels.map((label, index) => (
                    <li
                      key={index}
                      // onClick={() => toggleObjectTypeSegment(index)}
                      onClick={() => {
                        setIsSecondDataTable(true);
                        setIsThirdDataTable(true);
                        setShouldDisplayStatus(false);
                        fetchMonthData(
                          true,
                          control.getValues().dateStart,
                          control.getValues().dateEnd,
                          currentIntegration,
                          objectTypeData.labels[index],
                          currentMonth
                        );
                      }}
                      className={
                        selectedObjectTypeIndexes.includes(index)
                          ? "selected"
                          : ""
                      }
                    >
                      <span
                        style={{
                          backgroundColor:
                            objectTypeData.datasets[0].backgroundColor[index],
                        }}
                      ></span>
                      {label}
                    </li>
                  ))}
                </ul>
              )}
            </Grid>
            )}
        </>
      )}

      <CustomTable
        columns={columns}
        mainParam={"name"}
        data={transformedDataTable}
        option={option}
        setOption={setOption}
      />
    </>
  );
}
